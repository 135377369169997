import { useLoaderData } from '@remix-run/react'
import {
  json,
  LinksFunction,
  DataFunctionArgs,
  redirect,
} from '@remix-run/node'
import type { LoaderFunction, MetaFunction } from '@remix-run/node'
import type { getJobListItems } from '~/models/job.server'
import { getByCustomDomain, getBySlug } from '~/models/careerpage.server'

import { loadCareerPageData } from '~/careerpage.server'
import { Index as FrontpageIndex } from '~/components/frontpage'
import { PageConfiguration } from '~/types'

// @ts-ignore
import swiperCss from 'swiper/css/bundle'
// @ts-ignore
import swiperCssPagination from 'swiper/css/pagination'
import { getAccountById } from '~/models/account.server'
import { addEvent } from '~/models/event.server'
import { useSessionRewind } from '~/components/hooks/useSessionRewind'

type LoaderData = {
  data: {
    jobs: Awaited<ReturnType<typeof loadCareerPageData>>['jobs']
    account: Awaited<ReturnType<typeof getBySlug>>
    careerPage: Awaited<ReturnType<typeof getBySlug>>
  }
  livePreviewUrl: string
  includeSessionRewind: boolean
}

// @REFACTOR
function getSlug({
  request,
  context: rawContext,
  url,
}: {
  request: DataFunctionArgs['request']
  context: DataFunctionArgs['context']
  url: string
}) {
  const context: DataFunctionArgs['context'] & { subdomains: string[] } =
    rawContext as any

  const urlObj = new URL(url)

  const accountSlug =
    context.subdomains[context.subdomains.length - 1] ||
    urlObj.searchParams.get('slug')

  return accountSlug
}

export const loader: LoaderFunction = async ({ request, params, context }) => {
  let slug

  const slugFromUrl = getSlug({ request, context, url: request.url })

  if (slugFromUrl === 'app') {
    return redirect('/app')
  }

  // @REFACTOR
  const urlObj = new URL(request.url)
  const cpByCustomDomain = await getByCustomDomain(urlObj.hostname)

  if (cpByCustomDomain) {
    slug = cpByCustomDomain.slug
  }

  if (!slug) {
    slug = slugFromUrl
  }

  if (!slug && request.headers.has('referer')) {
    slug = getSlug({ request, context, url: request.headers.get('referer')! })
  }

  // if (!slug) {
  //   const urlObj = new URL(request.url)

  //   const slugQueryString = urlObj.searchParams.get("slug");

  // }

  // slug = 'aclaimant'

  if (!slug && process.env.NODE_ENV === 'development') {
    const { DEVELOPMENT_SLUG } = await import('~/__config')

    if (DEVELOPMENT_SLUG) {
      slug = DEVELOPMENT_SLUG
    }
  }

  // const url = new URL(request.url)

  // const accountSlug =
  //   context.subdomains[context.subdomains.length - 1] || url.searchParams.get("slug") || "join-3"

  if (!slug) {
    throw new Response('Not found', { status: 404 })
  }

  const cp = await getBySlug({ slug })

  if (!cp) {
    throw new Response('Not found', { status: 404 })
  }

  // try {
  //   if (cp.accountId) {
  //     const account = await getAccountById({ id: cp.accountId })
  //     if (account) {
  //       await addEvent({ name: 'page.visit', account, payload: { page: 'index', url: request.url, ip: context.ip } })
  //     }
  //   }
  // } catch (e) {
  //   console.error(e)
  // }

  let livePreviewUrl =
    process.env.NODE_ENV === 'development'
      ? `http://localhost:3000?slug=${cp?.slug}`
      : `https://${cp?.slug}.${process.env.DOMAIN_NAME_FOR_PREVIEW}`

  if (cp?.customDomain) {
    livePreviewUrl = `https://${cp.customDomain}`
  }

  const data = await loadCareerPageData({ careerPage: cp })

  const includeSessionRewind = process.env.NODE_ENV === 'production'

  // @REFACTOR @FIXME fix type definitions
  return json<{
    data: Awaited<ReturnType<typeof loadCareerPageData>>
    livePreviewUrl: string
    includeSessionRewind: boolean
  }>({ data, livePreviewUrl, includeSessionRewind })
}

export const meta: MetaFunction = ({ data }) => {
  const {
    data: { careerPage },
    livePreviewUrl,
  } = data as LoaderData
  const pageConfiguration = careerPage?.pageConfiguration as PageConfiguration
  const meta = pageConfiguration.props.meta

  const title = meta?.title ?? careerPage?.name ?? 'Homepage'
  const description = meta?.description ?? ''

  return {
    title,
    description,
    'og:type': 'website',
    'og:url': livePreviewUrl,
    'og:title': title,
    'og:description': description,
    'og:image':
      meta?.ogImageUrl?.replace(
        'https://s3.us-east-2.amazonaws.com/growhirestaging/',
        'https://d1icxrsvmhiz31.cloudfront.net/'
      ) ?? undefined,
    'twitter:card': meta?.ogImageUrl ? 'summary_large_image' : 'summary',
    'twitter:url': livePreviewUrl,
    'twitter:title': title,
    'twitter:description': description,
    'twitter:image':
      meta?.ogImageUrl?.replace(
        'https://s3.us-east-2.amazonaws.com/growhirestaging/',
        'https://d1icxrsvmhiz31.cloudfront.net/'
      ) ?? undefined,
  }
}

export const links: LinksFunction = () => {
  return [
    {
      rel: 'preload',
      as: 'style',
      href: '/style.css',
    },
    {
      rel: 'stylesheet',
      href: '/style.css',
    },
    {
      rel: 'stylesheet',
      href: swiperCss,
    },
    {
      rel: 'stylesheet',
      href: swiperCssPagination,
    },
    {
      rel: 'icon',
      href: '/favicon.png',
    },
  ]
}

export default function Index() {
  // const user = useOptionalUser();
  const { data, includeSessionRewind } = useLoaderData<LoaderData>()
  const { jobs, careerPage } = data

  const configuration: PageConfiguration =
    careerPage?.pageConfiguration as PageConfiguration

  useSessionRewind({ enabled: includeSessionRewind })

  return (
    <FrontpageIndex
      careerPage={careerPage!}
      jobs={jobs}
      configuration={configuration}
    />
  )
}
